<template>
    <div class="section index-section" key="documents">
        <div class="section-title">
            <h3>Welches Dokument haben Sie erhalten?</h3>
            <p>Bitte geben Sie an welches Dokument Sie von der Behörde erhalten haben. Diese Information finden Sie auf der ersten Seite des Schreibens.</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.document" name="radio-7" value="anhoerung" id="question-13-1">
            <label for="question-13-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-list-ahb.svg"></span><span>Anhörungsbogen</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.document" name="radio-7" value="zeugen" id="question-13-2">
            <label for="question-13-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-list-zfb.svg"></span><span>Zeugenfragenbogen</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.document" name="radio-7" value="bescheid" id="question-13-3">
            <label for="question-13-3" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-list-bgb.svg"></span><span>Bußgeldbescheid</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.document" name="radio-7" value="unsicher" id="question-13-4">
            <label for="question-13-4" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ask-question.svg"></span><span>Ich bin mir unsicher</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Document',
  computed: mapState({
    user: (state) => state.user
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.document = '';
  }
}
</script>